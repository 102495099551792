import { Form, Validators } from './form'

export const LoginForm = function(id) {
    var self = this;
    this.id = id || null;
    this.parent = Form.prototype;
    this.mode = "email"; // login in with email code or password?
    this.data = {
        "email": "",
        "password": "",
        "nonce" : null,
    };
    this.errorFields = [];

    this.validateRules = {
        "email"         : ["notEmpty"], 
        // "password"      : ["notEmpty"],
    };

    this.elements = {
        "authForm" : document.getElementById(this.id),
        "showAuthBtn" : document.getElementById('ecoactiv-show-auth-form'),
        "showAuthBtnMobile" : document.getElementById('ecoactiv-mobile-show-auth-form'),
        "authContainer" : document.getElementById('ecoactiv-auth-container'),
        "loginButton" : document.getElementById('ecoactiv_login_button'),
        "close" : document.getElementById('ecoactiv-auth-close'),
        "status" : document.querySelector('[data-message="status"]'),
        "passwordInput" : document.querySelector('input#password'),
        "signinWithPassword": document.getElementById('signin_with_password'),
        "signinWithEmail": document.getElementById('signin_with_email'),
        "signinWithPasswordText": document.getElementById('signin_with_password_text'),
        "signinWithEmailText": document.getElementById('signin_with_email_text'),
    };

    this.validateFields = Object.keys(this.validateRules);
    this.events();
    this.componentEvents();
};
LoginForm.prototype = new Form(Validators);
LoginForm.constructor = LoginForm;

LoginForm.prototype.componentEvents = function() {
    const self = this;

    if (self.elements.signinWithPassword) {
        self.elements.signinWithPassword.addEventListener('click', (e) => {
            e.preventDefault();
            this.resetMessages();
            this.mode = "password";
            self.elements.passwordInput.classList.remove('u-hide');        
            self.elements.signinWithEmailText.classList.remove('u-hide');        
            self.elements.signinWithPasswordText.classList.add('u-hide');
        });
    }

    if (self.elements.signinWithEmail) {
        self.elements.signinWithEmail.addEventListener('click', (e) => {
            e.preventDefault();
            this.resetMessages();
            this.mode = "email";
            self.elements.passwordInput.classList.add('u-hide');        
            self.elements.signinWithPasswordText.classList.remove('u-hide');        
            self.elements.signinWithEmailText.classList.add('u-hide');        
        });
    }


    if (self.elements.showAuthBtn) {
        self.elements.showAuthBtn.addEventListener('click', (e) => {
            e.preventDefault();
            self.elements.authForm.classList.add('show');        
            self.elements.showAuthBtn.parentElement.classList.add('hide');
        });
    }

    if (self.elements.showAuthBtnMobile) {
        self.elements.showAuthBtnMobile.addEventListener('click', (e) => {
            e.preventDefault();
            self.elements.authForm.classList.add('show');        
            self.elements.showAuthBtnMobile.parentElement.classList.add('hide');
        });
    }

    if (self.elements.close) {
        self.elements.close.addEventListener('click', () => {
            self.elements.authForm.classList.remove('show');
            self.elements.showAuthBtn.parentElement.classList.remove('hide');
        });
    }

}

LoginForm.prototype.submit = function(event) 
{
    var self = this;
    event.preventDefault();
    this.resetMessages();
    var validated = self.validate();
    var checkTerms = true;
    self.render(checkTerms);
    
    if (!validated) return;


    let data = {
        email: this.data.email,
        nonce: this.elements.authForm.querySelector('[name="ecoactiv_auth"]').value,
    };
    
    let action = "ecoactiv_email_login";
    if (this.mode === "password") {
        data['password'] = this.data.password;
        action = "ecoactiv_login";
    }

    if (data.email === "" || (this.mode === "password" && data.password === "")) {
        this.elements.status.classList.add('active');
        this.elements.status.innerHTML = 'Please fill out all fields';
        return;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderId = urlParams.get('orderId');
    const checksum = urlParams.get('chk');
    const source = urlParams.get('source');
    const campaign = urlParams.get('campaign');

    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("ecoactiv_auth", data.nonce);
    formData.append("action", action);

    if (this.mode === "password") {
        formData.append("password", this.data.password);
    }

    if (orderId) {  
        formData.append("orderId", orderId);
    }
    if (checksum) {
        formData.append("chk", checksum);
    }
    if (source) {
        formData.append("source", source);
    }
    if (campaign) {
        formData.append("campaign", campaign);
    }
    if (window.Acme.Page !== "") {
        formData.append("page", window.Acme.Page);
    }


    let url = this.elements.authForm.dataset.url;

    self.elements.loginButton.setAttribute("disabled", true);

    // for (var pair of formData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }

    fetch(url, {
        method: 'POST',
        body: formData
        }).then(res => res.json())
        .catch(error => {
            console.log(error);
            this.resetMessages();
            self.elements.loginButton.removeAttribute("disabled");
        })
        .then(response => {
            if (response.status === false) {
                this.elements.status.innerHTML = response.message;
                this.elements.status.classList.add('active');
                self.elements.loginButton.removeAttribute("disabled");
                return;
            }

            this.elements.status.innerHTML = response.message;
            this.elements.status.classList.add('success', 'active');
            this.elements.authForm.reset();
            self.elements.loginButton.removeAttribute("disabled");
            if (self.mode === 'password') {
                this.resetMessages();
                window.location.reload();
            }
    
        });

    
}
LoginForm.prototype.resetMessages = function() {
    this.elements.status.innerHTML = '';
    this.elements.status.classList.remove('success', 'error', 'active');
}
