import Graph from './graph.js';
import HalfCut from './halfcut.js';
import EnvImpact from './envImpact.js';
import Orders from './order.js';



const FetchUserMetrics = async function(nonce) {
    const url = new URL(window.location.origin + '/wp-json/ecoactiv/v1/user/metrics');
    // url.searchParams.append('orderId', orderId);
    try {
        var response = await fetch(url, {
            method: 'GET',
            headers: {
                "X-WP-Nonce": nonce
            }
        });
    } catch(e) {
        console.log(e);
        return [];
    }

    if (response.status !== 200) {
        return [];
    }

    // console.log(response);
    const data = await response.json();
    return data.data;
}


const FetchRecentContributions = async function(nonce) {
    const url = new URL(window.location.origin + '/wp-json/ecoactiv/v1/user/orders');
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            "X-WP-Nonce": nonce
        }
    });
    const data = await response.json();
    return data.data;
}





export const Dashboard = async function() {

    let nonce = '';
    const meta = document.querySelector('meta[name="cehub-dashboard-nonce"]');
    if (meta) {
        nonce = meta.content;
    }

    const [metrics, recent] = await Promise.all([
        FetchUserMetrics(nonce),
        FetchRecentContributions(nonce)
    ]);

    if (typeof metrics.materials !== 'undefined' ) {
        const graph = new Graph('materials_graph', metrics.materials);
        graph.render();
        new HalfCut(metrics.halfcut).render();
        new EnvImpact(metrics.envImpact).render();
    }
    
    if (typeof recent.orders !== 'undefined' ) {
        new Orders(recent.orders).render();
    }
}