/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
// [START maps_places_autocomplete_addressform]
// This sample uses the Places Autocomplete widget to:
// 1. Help the user select a place
// 2. Retrieve the address components associated with that place
// 3. Populate the form fields with those address components.
// This sample requires the Places library, Maps JavaScript API.
// Include the libraries=places parameter when you first load the API.
// For example: <script
// src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">
let autocomplete;
let address1Field;
// let address2Field;
let postalField;


export function initAutocomplete() {
  address1Field = document.querySelector("#reg_address");
  // address2Field = document.querySelector("#reg_address2");
  postalField = document.querySelector("#reg_postcode");
  // Create the autocomplete object, restricting the search predictions to
  // addresses in the US and Canada.
  autocomplete = new google.maps.places.Autocomplete(address1Field, {
    componentRestrictions: { country: ["au"] },
    fields: ["formatted_address", "address_components", "geometry"],
    types: ["address"],
  });
  address1Field.focus();
  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autocomplete.addListener("place_changed", fillInAddress);
}

// [START maps_places_autocomplete_addressform_fillform]
function fillInAddress() {
  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();
  let address1 = "";
  let postcode = "";

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components) {
    // @ts-ignore remove once typings fixed
    const componentType = component.types[0];
    console.log(componentType);
    console.log(component);
    console.log(address1);
    switch (componentType) {
      case "subpremise": {
        address1 = `${component.long_name}/`;
        break;
      }

      case "street_number": {
        address1 = `${address1}${component.long_name}`;
        break;
      }

      case "route": {
        address1 = `${address1} ${component.long_name}`;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }
      case "locality":
        address1 = `${address1} ${component.long_name},`;
        var suburb = document.querySelector("#reg_locality");
        suburb.value = component.long_name;
        suburb.dispatchEvent(new Event('change'));
        break;
      case "administrative_area_level_1": {
        address1 = `${address1} ${component.short_name}`;
        var state = document.querySelector("#reg_state");
        state.value = component.short_name;
        state.dispatchEvent(new Event('change'));
        break;
      }
      case "country":
        var country = document.querySelector("#reg_country");
        country.value = component.long_name;
        country.dispatchEvent(new Event('change'));
        break;
    }
  }
  console.log("final", address1);
  address1Field.value = address1;
  address1Field.dispatchEvent(new Event('change'));
  postalField.value = postcode;
  postalField.dispatchEvent(new Event('change'));
  
  // After filling the form with address components from the Autocomplete
  // prediction, set cursor focus on the second address line to encourage
  // entry of subpremise information such as apartment, unit, or floor number.
  // address2Field.focus();
}

window.initAutocomplete = initAutocomplete;
// [END maps_places_autocomplete_addressform]