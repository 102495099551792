import { Form, Validators } from './../form'
import { Toast } from '../notify'


export const AccountForm = function(id) {

    this.id = id || null;
    this.parent = Form.prototype;
    this.elements = {
        "accountForm" : document.getElementById(this.id),
        "status": document.getElementById('trade-details-account-form-status'),
        "submitButton": document.getElementById('save-account'),
        "accountCheck" : document.getElementById('account-check'),
        "form" : document.getElementById('ecoactiv-trade-account-form-container'),
        "expand" :  document.getElementById('view-trade-account'),
    };
    
    if (!this.elements.accountForm) return;


    this.data = {
        "account_name": "",
        "account_number": "",
        "bsb": "",
        "case_id": "",

    };
    this.errorFields = [];

    this.validateRules = {
        "account_name"     : ["notEmpty"],
        "account_number"   : ["notEmpty"],
        "bsb"              : ["notEmpty"]
    };

    this.validateFields = Object.keys(this.validateRules);
    this.events();
    this.componentEvents()
};

    AccountForm.prototype = new Form(Validators);
    AccountForm.constructor = AccountForm;
    AccountForm.prototype.markComplete = function(validated)
    {
        this.loadFieldsFromData();
        const isValid = typeof validated !== "undefined" ? validated : this.validate();
        
        this.elements.accountCheck.classList.remove('active');
        if ( isValid ) {
            this.elements.accountCheck.classList.add('active');
        }
        this.errorFields = [];
    }
    AccountForm.prototype.markInComplete = function(validated)
    {
        this.elements.accountCheck.classList.remove('active');
    }

    AccountForm.prototype.submit = function(event) 
    {
        var self = this;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        var validated = self.validate();
        this.render();
        this.markComplete(validated);
        if (!validated) return;

        const url = window.location.origin + '/wp-admin/admin-ajax.php?action=ecoactiv_update_profile';
        let postData = new FormData();
        postData.append('account_name', this.data.account_name);
        postData.append('account_number', this.data.account_number);
        postData.append('bsb', this.data.bsb);
        postData.append('case_id', this.data.case_id);

        let params = new URLSearchParams(postData);

        this.elements.submitButton.textContent = 'Saving...'
        this.elements.submitButton.setAttribute("disabled", true);

        fetch(url, {
            method: this.method,
            body: params
            }).then(res => res.json())
            .catch(error => {
                console.log(error);
                self.resetMessages();
            })
            .then(response => {
                console.log(response);
                if (response.success === false ) {
                    self.elements.status.innerHTML = response.data;
                    self.elements.status.classList.add('error');
                    // cnsolelog(self.elements.submitButton);
                    self.elements.submitButton.textContent = 'Save';
                    self.elements.submitButton.removeAttribute("disabled");
                    Toast({
                        type: 'error',
                        closeWith: 'click',
                        message: 'There was an error saving your account information. Please try again.',
                    });
                
                    // this.elements.submitButton.disabled = false;
                    return;
                }

                // Toast({
                //     message: 'Account information saved',
                // });
            
                self.elements.status.innerHTML = response.data;
                self.elements.status.classList.add('success');
                self.toggleForm();
                self.resetMessages();
            });

    }
    AccountForm.prototype.componentEvents = function()
    {
        const self = this;
        this.elements.expand.addEventListener('click', function (e) {
            e.preventDefault();
            self.toggleForm();

        });
    }
    AccountForm.prototype.toggleForm = function()
    {
        this.elements.form.classList.toggle('hidden');
    }

    AccountForm.prototype.resetMessages = function() {
        this.elements.status.innerHTML = '';
        this.elements.status.classList.remove('success', 'error');
        this.elements.submitButton.textContent = 'Save';
        this.elements.submitButton.disabled = false;
    }
