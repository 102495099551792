import { Form, Validators } from './../form'
import { Toast } from '../notify'

export const ProfileForm = function(id, nonce) {

    this.id = id || null;
    this.parent = Form.prototype;
    this.method = 'POST'; 
    this.elements = {
        "profileForm" : document.getElementById(this.id),
        "status" : document.querySelector('[data-message="status"]'),
        "submitButton": document.querySelector('button[type="submit"]'),
        "profileCheck" : document.getElementById('profile-check'),
        "form" : document.getElementById('ecoactiv-trade-detail-profile-form-container'),
        "expand" : document.getElementById('view-trade-profile'),
    
    };
    if (!this.elements.profileForm) return;


    this.data = {
        "case_id": "",
        "firstname": "",
        "lastname": "",
        "address": "",
        "phone": "",
        "email" : "",
        "nonce" : nonce
    };
    this.errorFields = [];

    this.validateRules = {
        "firstname"     : ["notEmpty"],
        "lastname"      : ["notEmpty"],
        "address"       : ["notEmpty"],
        "phone"         : ["notEmpty", "minLength|7"],
        "email"         : ["notEmpty"],

    };
    this.validateFields = Object.keys(this.validateRules);

    this.events();
    this.componentEvents()
};

    ProfileForm.prototype = new Form(Validators);
    ProfileForm.constructor = ProfileForm;
    ProfileForm.prototype.markComplete = function(validated)
    {
        this.loadFieldsFromData();
        const isValid = typeof validated !== "undefined" ? validated : this.validate();
        this.elements.profileCheck.classList.remove('active');
        if ( isValid ) {
            this.elements.profileCheck.classList.add('active');
        }
    }

    ProfileForm.prototype.submit = function(event) 
    {
        console.log(this.data);
        var self = this;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        var validated = self.validate();
        this.render();
        this.markComplete(validated);
        if (!validated) return;

        const url = window.location.origin + '/wp-admin/admin-ajax.php?action=ecoactiv_update_profile';
        let postData = new FormData();
        postData.append('firstname', this.data.firstname);
        postData.append('lastname', this.data.lastname);
        postData.append('address', this.data.address);
        postData.append('phone', this.data.phone);
        postData.append('email', this.data.email);
        postData.append('case_id', this.data.case_id);

        let params = new URLSearchParams(postData);

        this.elements.submitButton.innerHTML = 'Saving...'
        this.elements.submitButton.setAttribute("disabled", true);

        
        fetch(url, {
            method: this.method,
            body: params
            }).then(res => res.json())
            .catch(error => {
                console.log(error);
                self.resetMessages();
            })
            .then(response => {
                // console.log(response);
                if (response.success === false ) {                    
                    self.elements.status.innerHTML = response.message;
                    self.elements.status.classList.add('error');
                    Toast({
                        type: 'error',
                        closeWith: 'click',
                        message: 'There was an error saving your address information. Please try again.',
                    });
                    return;
                }

                Toast({
                    message: 'Address details saved',
                });
                self.elements.status.innerHTML = response.message;
                self.elements.status.classList.add('success');
                self.resetMessages();
                self.toggleForm();
            });

    }
    ProfileForm.prototype.toggleForm = function()
    {
        this.elements.form.classList.toggle('hidden');
    }
    ProfileForm.prototype.componentEvents = function()
    {
        const self = this;
        this.elements.expand.addEventListener('click', function (e) {
            e.preventDefault();
            self.toggleForm();
        });
    }
    ProfileForm.prototype.resetMessages = function() {
        this.elements.status.innerHTML = '';
        this.elements.status.classList.remove('success', 'error');
        this.elements.submitButton.innerHTML = 'Register';
        this.elements.submitButton.disabled = false;
    }



