const Templates = {
    Modal : '\
    <div id="{{name}}" class="flex_col {{name}}"> \
        <div id="dialog" class="{{name}}__window"> \
            <div class="{{name}}__container" style="scrolling == unusable position:fixed element"> \
                <div class="{{name}}__header"> \
                    <a class="{{name}}__close close" href="#" data-role="close"></a> \
                </div> \
                <div class="{{name}}__content-window" id="dialogContent" style="scrolling == unusable position:fixed element"></div> \
            </div> \
        </div> \
    </div>',

    Spinner : '<div id="{{name}}" class="flex_col {{name}}"> \
        <div class="{{name}}__content-window" id="dialogContent" style="scrolling == unusable position:fixed element"></div> \
    </div>',
    Impact : ' \
        <div class="impact-modal__content-container"> \
            <img class="impact-modal__image u-margin-bottom-30" src="https://ecoactiv.com.au/wp-content/uploads/2020/10/impact-modal.png" alt="Impact Modal" /> \
            <h1 class="impact-modal__heading u-margin-bottom-30">Congratulations!<br />You’re making an impact <br />& have earned 10 points.</h1> \
            <p class="impact-modal__text u-margin-bottom-30">You can expect to receive your cashback within <span>14 days<span></p> \
            <a href="" class="impact-modal__button">See my impact</a> \
        </div>',

    Spin: '<span class="loader"></span>',
    Basic : '<div id="basic" class=""> \
        <button data-role="close">Close</button> \
        <button>Submit</button> \
    </div>'

}

export default Templates;