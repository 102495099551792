export const Templater = function() {}
Templater.prototype.compile = function (template, data) {
    var self = this;
    'use strict';

    // Check if the template is a string or a function
    template = typeof (template) === 'function' ? template() : template;
    if (['string', 'number'].indexOf(typeof template) === -1) throw 'PlaceholdersJS: please provide a valid template';

    // If no data, return template as-is
    if (!data) return template;

    // Replace our curly braces with data
    template = template.replace(/\{\{([^}]+)\}\}/g, function (match) {
        // Remove the wrapping curly braces
        match = match.slice(2, -2);
        // Get the value
        var val = self.get(data, match.trim());
        // Replace
        if (!val) return '';
        return val;

    });

    return template;

};    
Templater.prototype.get = function (obj, path, def) {

    /**
     * If the path is a string, convert it to an array
     * @param  {String|Array} path The path
     * @return {Array}             The path array
     */
    var stringToPath = function (path) {

        // If the path isn't a string, return it
        if (typeof path !== 'string') return path;

        // Create new array
        var output = [];

        // Split to an array with dot notation
        path.split('.').forEach(function (item) {
            // Split to an array with bracket notation
            item.split(/\[([^}]+)\]/g).forEach(function (key) {
                // Push to the new array
                if (key.length > 0) {
                    output.push(key);
                }
            });
        });

        return output;
    };

    // Get the path as an array
    path = stringToPath(path);

    // Cache the current object
    var current = obj;

    // For each item in the path, dig into the object
    for (var i = 0; i < path.length; i++) {
        // If the item isn't found, return the default (or null)
        if (!current[path[i]]) return def;
        // Otherwise, update the current  value
        current = current[path[i]];
    }

    return current;

};

Templater.prototype.appendTo = function (elem, html) 
{
    const node = document.getElementById(elem);
    if (!node) return;
    node.insertAdjacentHTML('beforeend', html);
}

Templater.prototype.appendToElem = function (elem, html) 
{
    if (!elem) return;
    elem.insertAdjacentHTML('beforeend', html);
}
