const listen = function() {};
listen.prototype.listener = function(topic, data)
{
    var keys = Object.keys(data);
    for (var i = 0; i<keys.length; i++) {
        for (var listener in this.listeners) {
            if ( listener === keys[i] ) {
                this.listeners[listener].call(this, data, topic);
                if (this.listeners.after) {
                    this.listeners.after.call(this, data, topic);
                }
                break;
            }
        }
    }
};


export const View = function() {};
View.prototype = new listen();
View.prototype.updateData = function(data) {
    var keys = Object.keys(data);
    // if the form has name fields with different names than the data keys, we need to convert them
    const regex = /form_fields\[(.+?)\]/i;

    for (var j=0; j<keys.length; j++) {
        // keep dataKey the as original for setting value later
        var key = keys[j], dataKey = key;
        var key = key.replace(regex, '$1');
        var keySplit = key.split('.');
        var scope = this.data;
        for(var i=0; i<keySplit.length; i++) {
            if (!scope[keySplit[i]]) {
                scope[keySplit[i]] = {};
            }
            if(i == keySplit.length -1 ) {
                scope[keySplit[i]] = data[dataKey];
            }
            scope = scope[keySplit[i]];
        }
    }
}