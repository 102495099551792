import { PubSub } from './PubSub'
import { LoginForm } from './authLogin'
import { TradeProfile } from './profile'
import { Dashboard } from './dashboard/dashboard'
import { RegisterForm, CashbackForm} from './authRegister'
import { initAutocomplete }  from './googleAddress'
//     ConfirmView.subscriptions = PubSub.subscribe({
//         'Acme.confirmView.listener' : ['update_state']
//     });
// Acme.PubSub.publish("update_state", data);

document.addEventListener('DOMContentLoaded', function (e) {
    window.Acme = window.Acme || {};
    window.Acme.View = {};
    window.Acme.PubSub = PubSub;
    const path = window.location.pathname;
    window.Acme.Page = path.replace(/^\/+|\/+$/g, '');
    
    const Login = new LoginForm('ecoactiv_auth_form');
    const Register = new RegisterForm('ecoactiv_register_form');
    const CashBack = new CashbackForm('ecoactiv_cashback_form');

    
    if (window.Acme.Page === 'trade-in-details') {
        const Profile = new TradeProfile('ecoactiv_trade_profile');
    }

    if (window.Acme.Page === 'dashboard' || window.Acme.Page === 'dashtest') {
        const Dash = new Dashboard();

    }

});