export const PubSub = {
    topics : {},
    lastUid : -1,
};

    PubSub.publisher = function(topic, data) {
        var self = this;
        var Deferred = function() {
            return {
                done: function(func) {
                    this.func = func;
                },
                resolve: function() {
                    if (this.func) {
                        this.func();
                    }
                }
            }
        };

        if ( !this.topics.hasOwnProperty( topic ) ){
            return false;
        }

        var dfd = Deferred();

        var notify = function(){
            var subscribers = self.topics[topic];

            for ( var i = 0, j = subscribers.length; i < j; i++ ){
                var scope = window;
                var scopeSplit = subscribers[i].context.split('.');

                for (var k = 0; k < scopeSplit.length - 1; k++) {
                    scope = scope[scopeSplit[k]];
                    if (scope == undefined) return;
                }

                var caller = scope[scopeSplit[scopeSplit.length - 1]];
                var func   = subscribers[i].func;
                if (caller) {
                    caller[func]( topic, data );
                }
            }
            dfd.resolve();
        };

        setTimeout( notify , 0 );

        return dfd;
    };

    PubSub.publish = function( topic, data ){
        return this.publisher( topic, data, false );
    };

    PubSub.reset = function( ){
        this.lastUid = -1;
    };

    PubSub.print = function(){
        var subscribers = this.topics;
        for (var sub in subscribers) {
            for ( var i = 0; i < subscribers[sub].length; i++ ) {
            }
        }
    };

    PubSub.subscribe = function( subscription ) {
        var callbacks = Object.keys(subscription);
        var ret_topics = {};

        for (var i=0;i<callbacks.length; i++) {
            for(var j=0;j<subscription[callbacks[i]].length;j++) {
                var topic = subscription[callbacks[i]][j];

                var context = callbacks[i].substring(0, callbacks[i].lastIndexOf('.'));

                var func = callbacks[i].substring(callbacks[i].lastIndexOf('.') + 1);

                if ( !this.topics.hasOwnProperty( topic ) ) {
                    this.topics[topic] = [];
                }

                for (var k=0;k<this.topics[topic].length; k++) {
                    if (this.topics[topic][k].context === context && this.topics[topic][k].func === func) {
                        return;
                    }
                }

                var token = (++this.lastUid).toString();

                this.topics[topic].push( { token : token, func : func, context : context } );
                ret_topics[topic] = this.topics[topic];
            }

        }
        return ret_topics;
    };

    PubSub.unsubscribe = function( token ){
        for ( var m in this.topics ){
            if ( this.topics.hasOwnProperty( m ) ){
                for ( var i = 0, j = this.topics[m].length; i < j; i++ ){
                    if ( this.topics[m][i].token === token ){
                        this.topics[m].splice( i, 1 );
                        return token;
                    }
                }
            }
        }
        return false;
    };
