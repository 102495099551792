import Templates from './templates'
import {Templater} from './templater'


export const Modal = function(template, name, layouts, data) {
    this.template = template || null;

    // Templates (layouts) are imported at the top of this file, however if you wish
    // to use a seperate set of templates, you store them here;
    this.templates = false;
    
    this.parentCont = name   || null;
    this.layouts = layouts   || null;
    this.data = data         || {};
    // this.dfd = $.Deferred();
    return this;
}
    // Modal.prototype = new Acme.listen();
    Modal.prototype.setTemplates = function(templates) {
        this.templates = templates;
    };
    Modal.prototype.loadTemplate = function(tmp) {
        let template = false;
        if (this.templates) {
            template = this.templates[tmp] || false;
        } 
        if (typeof template === 'undefined' || template === false) {
            template = Templates[tmp] || false;
        }
        
        if (!template) {
            console.log(tmp, 'template missing');
            return false;
        }
        return template;
    };
    Modal.prototype.render = function(layout, title, data) {
        var preRendered = false;
        if (typeof data === 'string') {
            preRendered = true;
        } else {
            this.data = data || this.data;
        }

        if (title) {
            this.data['title'] = title;
        }


        this.data['name'] = this.parentCont;
        const template = this.loadTemplate(this.template);
        var tmp = template;
        var tmp = this.compile(template, this.data);
        document.documentElement.classList.add('u-noscroll');
        document.body.classList.add('u-noscroll');
        document.body.insertAdjacentHTML('beforeend', tmp);


        if (layout) {
            this.renderLayout(layout, this.data);
        }
        if (preRendered) {
            this.renderPreLayout(data);
        }

        this.events();
        this.rendered(); // lifecycle hook that can be overriden
        // return this.dfd.promise();
        return this;
    };
    Modal.prototype.renderLayout = function(layout, data) {
        var data = data || {};
        var layoutTemplate = false;
        if (this.layouts !== null && typeof this.layouts[layout] !== 'undefined') {
            layoutTemplate = this.loadTemplate(this.layouts[layout]);
        } else {
            layoutTemplate = this.loadTemplate(layout);
        }

        if (layoutTemplate) {
            const layoutHtml = this.compile(layoutTemplate, data);
            var append = document.getElementById('dialogContent');
            if (append) append.innerHTML = layoutHtml;
        } else {
            console.log(layout, 'Does not exist' );
        }

        // if (typeof data.modal_title != 'undefined') {
        //     $('.signin-modal__title').text(data.modal_title);
        // }
    };
    Modal.prototype.renderPreLayout = function(html) {
        var append = document.getElementById('dialogContent');
        if (append) append.innerHTML = layoutHtml;
};
    Modal.prototype.events = function() 
    {
        var self = this;
        document.getElementById(this.parentCont).addEventListener('click', function(e) {
            self.handle(e);
        });

    };
    Modal.prototype.rendered = function() {
        return true;
    };
    Modal.prototype.handle = function(e) {
        var $elem = e.target;
    
        const elemType = $elem.nodeName.toLowerCase();
   
        const role = $elem.dataset.role;

        const closeRoles = ['close', 'cancel', 'okay'];
        if (closeRoles.includes(role)) {
            e.preventDefault();
            this.closeWindow();
        }

        return $elem;
    };
    Modal.prototype.closeWindow = function() {
        document.documentElement.classList.remove('u-noscroll');
        document.body.classList.remove('u-noscroll');
        document.getElementById(this.parentCont).remove();

    };
    Modal.prototype.compile = function (template, data) {
       const templater = new Templater();
       return templater.compile(template, data);
    };    
   

