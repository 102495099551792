import { Modal } from './modal'
// import { PubSub } from './framework'
import { Templater } from './templater'
import { AccountForm } from './trade/AccountForm'
import { ProfileForm } from './trade/ProfileForm'
import { CompleteForm } from './trade/CompleteForm'
import { StepsComponent } from './trade/StepsComponent'


const FetchPageData = async function(nonce, orderId) {
    const url = new URL(window.location.origin + '/wp-json/ecoactiv/v1/user/order');
    url.searchParams.append('orderId', orderId);
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            "X-WP-Nonce": nonce
        }
    });
    const data = await response.json();
    return data;
}






export const TradeProfile = async function(id) {


    let nonce = '';
    const meta = document.querySelector('meta[name="cehub-trade-nonce"]');
    if (!meta) {
        alert('Trade Nonce missing');
    }
    
    nonce = meta.content;
    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const orderId = urlParams.get('orderId');
    
    const orderStatus = document.getElementById('trade-in-status');
    const orderStatusColor = document.getElementById('trade-in-status-color');
   
    // We remove these from the page if there is an error fetching the order
    const Actions = document.getElementById('trade-details-actions'); 
    const Steps = document.getElementById('trade-details-steps'); 
    const spinner = new Modal('Spinner', 'spinner').render("Spin");

    let response = false;
    
    if (orderId) {
        response = await FetchPageData(nonce, orderId);
    }
    if (!orderId || (response && response.status == 'error')) {
        spinner.closeWindow();
        Actions.remove();
        Steps.remove();
        const Tmplater = new Templater();
        const error = Tmplater.compile("<h1>{{message}}</h1>", {"message": "There was an error fetching your order. Please try again."});
        Tmplater.appendTo("trade-details-container", error);
        return;
    }



    const user = response.data?.user;
    const order = response.data?.order;
    const contact = response.data?.contact;
    
    const userData = {
        "case_id": orderId,
        "firstname": contact.firstname ?? user.firstname,
        "lastname": contact.lastname ?? user.lastname,
        "address": contact.address ?? user.address,
        "phone": contact.phone ?? user.phone,
        "email": contact.email ?? user.email,
    };

    const Profile = new ProfileForm('ecoactiv-trade-detail-profile-form', nonce);
    Profile.setData(userData).markComplete();



    const accountData = {
        "case_id": orderId,
        "account_name": order.account_name ?? user.account_name,
        "account_number": order.account_number ?? user.account_number,
        "bsb": order.bsb ?? user.bsb
    }
    const Account = new AccountForm('ecoactiv-trade-detail-account-form', nonce);
    Account.setData(accountData).markComplete();
   
    

    const Complete = new CompleteForm('ecoactiv-trade-detail-complete-form', orderId);
    const completeData = {
        "completed": order.status === 'sent' ? true : false,
    }
    Complete.setData(completeData).markComplete();



    // const orderData = {
    //     "order_id": order.id,
    //     "status": order.status,
    // }

    orderStatus.textContent = order.status.toUpperCase();


    spinner.closeWindow();


    // const impactModal = new Modal('Modal', 'impact-modal').render("Impact", "Congrats");
    // impactModal.render("Impact", "Congrats");
    Acme.View.StepsComponent = new StepsComponent();
    Acme.View.StepsComponent.setStatus(order.status);
    Acme.View.StepsComponent.render();
    // Acme.PubSub.publish("update_state", {'trade_status': "recieved" });

}

