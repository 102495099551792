import { Form, Validators } from './../form'


export const CompleteForm = function(id, orderId) {

    this.id = id || null;
    this.parent = Form.prototype;
    this.method = 'POST'; 
    this.orderId = orderId;
    this.elements = {
        "completeForm" : document.getElementById(this.id),
        "submitButton": document.getElementById('complete-trade'),
        "completeCheck" : document.getElementById('complete-check'),
    };
    if (!this.elements.completeForm) return;

    // console.log(this.elements.status);
    this.data = {
        "completed": false
    };
    this.errorFields = [];

    this.validateRules = {
        "completed" : ["isTrue"]
    };

    this.validateFields = Object.keys(this.validateRules);
    this.events();
};

    CompleteForm.prototype = new Form(Validators);
    CompleteForm.constructor = CompleteForm;
    CompleteForm.prototype.markComplete = function(validated)
    {
        const isValid = typeof validated !== "undefined" ? validated : this.validate();
        this.elements.completeCheck.classList.remove('active');
        if ( isValid ) {
            this.elements.completeCheck.classList.add('active');
            this.elements.submitButton.remove();
        }
    }


    CompleteForm.prototype.submit = function(event) 
    {
        var self = this;
        
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.data.completed = true;
        var validated = self.validate();
        this.render();
        if (!validated) return;
        
        const url = window.location.origin + '/wp-admin/admin-ajax.php?action=ecoactiv_complete_trade';
        let postData = new FormData();
        postData.append('status', "sent");
        postData.append('order_id', this.orderId);

        let params = new URLSearchParams(postData);

        this.elements.submitButton.innerHTML = 'Saving...'
        this.elements.submitButton.setAttribute("disabled", true);

        
        fetch(url, {
            method: this.method,
            body: params
            }).then(res => res.json())
            .catch(error => {
                console.log(error);
            })
            .then(response => {
                console.log(response);
                if (response.success === false) {
                    // self.elements.status.innerHTML = response.message;
                    // self.elements.status.classList.add('error');
                    self.elements.submitButton.innerHTML = 'Mark complete'
                    self.elements.submitButton.removeAttribute("disabled");
                    return;
                }
                console.log('in success handler');
                // self.elements.status.innerHTML = response.message;
                // self.elements.status.classList.add('success');
                self.elements.submitButton.remove();
        
                self.markComplete(validated);
                Acme.PubSub.publish("update_state", {'trade_status': "sent" });
            });

    }