class Notify {

    render(options) {
        var tag = document.createElement("div");
        tag.setAttribute("data-test", "notify");
        tag.classList.add("c-notify");
        var textNode = document.createTextNode(options.message);
        tag.appendChild(textNode);


        if (options.type === 'success') {
            tag.classList.add("c-notify--success");
        }
        if (options.type === 'error') {
            tag.classList.add("c-notify--error");
        }

        document.body.appendChild(tag);
        setTimeout( () => { 
            tag.classList.add("c-notify--active");
        }, 0);


        if (typeof options.closeWith === 'undefined') {
            setTimeout( () => { 
                tag.classList.remove("c-notify--active");
                // tag.parentNode.removeChild(tag);
            }, options.timeout);
        } else {
            if (options.closeWith === "click") {
                tag.addEventListener('click', function(e) {
                    this.classList.remove("c-notify--active");
                });
            }
        }
    }
}

export const Toast = function (options) {
    var defaults = {
        message: '',
        type: 'success',
        timeout: 2500,
    };

    let opts = {...defaults, ...options};
    var n = new Notify();
    n.render(opts);

};

//Show Error Message
export const General_ShowErrorMessage = function (options) {
    var defaults = {
        message: '',
        type: 'error',
        closeWith: 'click',

    };

    let opts = {...defaults, ...options};    
    var n = new Notify();
    n.render(opts);
};
