import { View } from '../view.js';
import { Templater } from '../templater'


const Orders = function(data) {
    this.data = data;

    this.elements = {
        score: document.getElementById('recent-orders'),
    }

    return this;
}

Orders.prototype = new View();
Orders.constructor = Orders;

Orders.prototype.render = function() 
{
    const Tmplater = new Templater();
    const temp = '<a href="{{link}}"><div class="recent-item u-flex"> \
        <div class="recent-item__image"> \
            <img src="{{image}}" alt=""> \
        </div> \
        <div class="recent-item__content"> \
            <h2 class="recent-item__title">{{name}}</h2> \
            <p class="recent-item__sub-title">{{description}}</p> \
        </div> \
    </div></a>';

    const origin = window.location.origin;
    
    const html = [];
    for (let order of this.data) {
        const url = `${origin}/trade-in-details/?orderId=${order.external_order_id}&program_id=${order.program_slug}`;
        const templateData = {
            "name": order.program_name,
            "image": order.program_image || "https://via.placeholder.com/100",
            "description": order.program_description || "",
            "link": url,
        };
        
        html.push( 
            Tmplater.compile(temp, templateData)
        );
    }
    Tmplater.appendToElem(this.elements.score, html.join(''));
}


export default Orders;



// https://letsgo.ecoactiv.com.au/api/programs/categories?programId=6
// https://letsgo.ecoactiv.com.au/api/programs/6/categories/34/subcategories
// https://letsgo.ecoactiv.com.au/api/programs/6/subcategories/168/items
// https://letsgo.ecoactiv.com.au/api/items/8426/programs/6