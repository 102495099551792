import { View } from '../view.js';



const EnvImpact = function(data) {
    this.data = data;

    this.elements = {
        score: document.getElementById('env_impact_score'),
    }

    return this;
}

EnvImpact.prototype = new View();
EnvImpact.constructor = EnvImpact;

EnvImpact.prototype.render = function() 
{
    this.elements.score.innerHTML = (this.data) ? this.data.value + this.data.unit_type : "";
}


export default EnvImpact;