import { Form, Validators } from './form'



export const RegisterForm = function(id) {

    this.id = id || null;
    this.parent = Form.prototype;
     
    this.elements = {
        "registerForm" : document.getElementById(this.id),
        "status" : document.getElementById("auth-register-status"),
        "submitButton": document.getElementById('register-user')
    };
    if (!this.elements.registerForm) return;

    let nonce = '';
    const meta = document.querySelector('meta[name="cehub-register-nonce"]');
    if (meta) {
        nonce = meta.content;
    } 

    this.data = {
        "firstname": "",
        "lastname": "",
        "address": "",
        "postcode": "",
        "locality": "",
        "state": "",
        "phone": "",
        "email": "",
        "terms": false,
        "password": "", // only used for testing
        "nonce" : nonce
    };

    this.validateRules = {
        "firstname"     : ["notEmpty"],
        "lastname"      : ["notEmpty"],
        "address"       : ["notEmpty"],
        "phone"         : ["notEmpty", "minLength|7"],
        "email"         : ["notEmpty"],
        "terms"         : ["isSelected"],
    };

    this.validateFields = Object.keys(this.validateRules);
    this.events();
    this.componentEvents();
};

RegisterForm.prototype = new Form(Validators);
RegisterForm.constructor = RegisterForm;
RegisterForm.prototype.submit = function(event) 
{
    var self = this;
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }
    var validated = self.validate();
    this.render();
    if (!validated) return;

    const url = window.location.origin + '/wp-admin/admin-ajax.php';
    let postData = new FormData();
    postData.append('action', 'ecoactiv_register');
    postData.append('firstname', this.data.firstname);
    postData.append('lastname', this.data.lastname);
    postData.append('address', this.data.address);
    postData.append('postcode', this.data.postcode);
    postData.append('suburb', this.data.locality);
    postData.append('state', this.data.state);
    postData.append('phone', this.data.phone);
    postData.append('email', this.data.email);
    postData.append('cehub_nonce', this.data.nonce);

    if (this.data.password) postData.append('password', this.data.password);
 
    let params = new URLSearchParams(postData);
    this.elements.submitButton.innerHTML = 'Registering...';
    this.elements.submitButton.setAttribute("disabled", true);

    fetch(url, {
        method: 'POST',
        body: params
        }).then(res => res.json())
        .catch(error => {
            console.log(error);
            self.resetMessages();
        })
        .then(response => {
            if (response === 0 || response.code !== 201) {
                self.resetMessages();
                return;
            }

            window.location.reload();
    
        });

}

RegisterForm.prototype.resetMessages = function() {
    this.elements.status.innerHTML = '';
    this.elements.status.classList.remove('success', 'error');
    this.elements.submitButton.innerHTML = 'Register';
    this.elements.submitButton.disabled = false;
}
RegisterForm.prototype.componentEvents = function() {
    var self = this;
    const loginButton = document.getElementById('ecoactiv-show-auth-form');
    const alreadyMember = document.getElementById('already-member');
    if (alreadyMember && loginButton) {
        alreadyMember.addEventListener('click', function (e) {
            e.preventDefault();
            loginButton.dispatchEvent(new Event('click'));
        });
    }
}




export const CashbackForm = function(id) {
    var self = this;
    this.id = id || null;
    this.parent = Form.prototype;
    
    this.elements = {
        "cashBackForm" : document.getElementById(this.id),
        "submitButton": document.querySelector('button[type="submit"]'),
        "status" : document.getElementById('cashback-status'),

    };

    if (!this.elements.cashBackForm) return;


    let nonce = '';
    const meta = document.querySelector('meta[name="cehub-register-nonce"]');
    if (meta) {
        nonce = meta.content;
    } 

    this.data = {
        "nonce" : nonce
    };

    this.events();
};
CashbackForm.prototype = new Form(Validators);
CashbackForm.constructor = CashbackForm;
CashbackForm.prototype.submit = function(event) 
{
    var self = this;
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let postData = new FormData();

    for (const [key, value] of urlParams.entries()) {
        postData.append(key, value);
    }
    
    // get the organisation from the url
    const path = window.location.pathname.split('/');
    let organisation = "";
    if ( path.length > 0 ) {
        organisation = path[1];
    }

    let params = new URLSearchParams(postData);
    this.elements.submitButton.value = 'Starting...';
    this.elements.submitButton.disabled = true;
    const url = window.location.origin + `/wp-json/ecoactiv/v1/order?org=${organisation}`;

    fetch(url, {
        method: 'POST',
        body: params,
        headers: {
            "X-WP-Nonce": this.data.nonce
          }
      
        }).then(res => res.json())
        .catch(error => {
            console.log(error);
            // this.resetMessages();
        })
        .then(response => {
            console.log(response);
            if (response.code !== 201) {
                this.elements.status.innerHTML = response.message;
                this.elements.status.classList.add('error');
                return;
            }
            this.elements.status.classList.add('success');
            this.elements.cashBackForm.reset();
            const url = new URL(window.location.origin + '/trade-in-details');
            const orderId = response.data.order.external_order_id;
            const program = response.data.program.slug;
            url.searchParams.append('orderId', orderId);
            url.searchParams.append('program_id', program);
            window.location.href = url;
    
        });

    this.resetMessages();
}
CashbackForm.prototype.resetMessages = function() {
    this.elements.status.innerHTML = '';
    this.elements.status.classList.remove('success', 'error');
    this.elements.submitButton.value = 'Login';
    this.elements.submitButton.disabled = false;
}
