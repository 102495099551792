import { View } from '../view'

export const StepsComponent = function() {
    var self = this;
    this.statusList = [ 
        "pending",
        "sent",
        "recieved",
        "complete"
    ];
    this.status = 0;
    this.elements = {
        "container" : document.getElementById('trade-details-steps'),
        "steps" : document.querySelectorAll('.trade-details-steps__item'),
        "status" : document.getElementById('trade-in-status'),
        "statusColour" : document.getElementById('trade-in-status-color'),
    };
    this.subscriptions = Acme.PubSub.subscribe({
        'Acme.View.StepsComponent.listener' : ["update_state"]
    });

    this.listeners = {
        "trade_status": function(data) {
            self.setStatus(data['trade_status']).render();
        }
    };

}
StepsComponent.prototype = new View();
StepsComponent.constructor = StepsComponent;
StepsComponent.prototype.setStatus = function(status) {
    this.status = this.statusList.indexOf(status.toLowerCase());
    return this;
}

StepsComponent.prototype.render = function() {
    this.elements.steps.forEach((step, index) => {
        if (index <= this.status) {
            step.classList.add('active');
        }
    });
    this.elements.status.textContent = this.statusList[this.status].toUpperCase();
    return this;
}