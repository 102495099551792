import Chart from 'chart.js/auto';
import { View } from '../view.js';


const Graph = function(elemId, materials) {
   
    this.container = document.getElementById(elemId);

    const labels = [];
    const data = [];
    if (!materials) {
        return;
    }
    
    for (let [key, value] of Object.entries(materials)) {
        labels.push(key);
        data.push(value);
    }

    this.data = {
        labels,
        datasets: [{
            label: 'Materials recovered',
            data,
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(55, 205, 12)',
                'rgb(100, 90, 86)',
                'rgb(255, 12, 186)',
            ],
            hoverOffset: 4
        }]
    };

}
Graph.prototype = new View();
Graph.constructor = Graph;

Graph.prototype.render = function() {
    const config = {
        type: 'doughnut',
        data: this.data,
        options: {
            cutout: '75%',
            showDatasetLabels : true,
            cutoutPercentage: 41,
            plugins: {
                legend: {
                    display: true,
                    position:'bottom',
                    labels: {
                        color: 'rgb(255, 99, 132)'
                    }
                }
            }
        }
    };

    new Chart(this.container, config);
}


export default Graph;