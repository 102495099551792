import { View } from './view'


/***                             ****
    Base Class for all Forms
***                              ****/
export const Form = function(validators, rules) {
    this.data;
    this.errorField;
    this.errorFields = [];
    this.validators = validators || null;
    this.validateRules = rules || {};
    this.touched = false;
    this.errorMessages = [];

};

Form.prototype = new View();
Form.constructor = Form;
Form.prototype.setData = function(data)
{
    this.data = data;
    this.method = 'PATCH';
    return this;
}
Form.prototype.loadDataFromFields = function()
{
    for (let name in this.validateFields) {
        var fieldname = this.validateFields[name].split('.').reverse()[0];
        let field = document.querySelector('#' + this.id +' #'+fieldname);
        if (!field || !field.length) {
            let query = '#' + this.id + ' input[name="'+fieldname + '"]';
            field = document.querySelector(query);
        }
        if (!field.length) {
            continue;
        }
        var fieldType = field.type;
        if (fieldType === 'hidden') continue;
        
        if (fieldType === 'checkbox') {
            this.data[fieldname] = field.checked;
        } else {
            this.data[fieldname] = field.value;
        }
    }
};
Form.prototype.loadFieldsFromData = function()
{
    for (let name in this.validateFields) {
        var fieldname = this.validateFields[name].split('.').reverse()[0];
        let fieldElem = document.querySelector('#' + this.id +' #'+fieldname);
        if (!fieldElem) {
            let query = '#' + this.id + ' input[name="'+fieldname + '"]';
            fieldElem = document.querySelector(query);
        }
        if (!fieldElem) {
            continue;
        }
        var fieldType = fieldElem.type;
        if (fieldType === 'hidden') {
            continue;
        }
 
        if (typeof this.data[fieldname] === "undefined") {
            continue;
        }
        if (fieldType === 'checkbox') {
            fieldElem.checked = this.data[fieldname];
        } else {
            fieldElem.value = this.data[fieldname];
        }
    }
};

Form.prototype.clearInlineErrors = function()
{
    if (this.errorField) {
        this.errorField.classList.remove('active');
    }
    for (let field in this.validateFields) {
        var fieldname = this.validateFields[field].split('.').reverse()[0];
        let fieldElem = document.querySelector('#' + this.id +' #'+fieldname);

        if (!fieldElem) {
            const query = '#'+ this.id + ' input[name="'+fieldname + '"]';
            fieldElem = document.querySelector(query);
        }
        if (!fieldElem) continue;
        fieldElem.classList.remove('formError');
    }
};
Form.prototype.addInlineErrors = function()
{
    let errorList = document.querySelector('#' + this.id + ' .errorList');
    if  (errorList) {
        errorList.innerHTML = '';
    }

    if (this.errorFields.length > 0 && this.errorField) {
        this.errorField.classList.add('active');
    }
    for (var field in this.errorFields) {
        let fieldElem = document.querySelector('#' + this.id +' #'+this.errorFields[field]);
        if (!fieldElem) {
            const query = '#'+ this.id + ' input[name="'+this.errorFields[field] + '"]';
            fieldElem = document.querySelector(query);
        }
        if (!fieldElem) {
            console.log('continuing');
            continue;
        }
        
        fieldElem.classList.add('formError');
    }

    if (this.errorMessages.length > 0) {
        if (!errorList) {
            return;
        }
        for (var i=0; i<this.errorMessages.length; i++) {
            let li = document.createElement('li');
            li.innerHTML = this.errorMessages[i];
            errorList.appendChild(li);
        }
    }
};
Form.prototype.render = function() {
    this.clearInlineErrors();
    this.addInlineErrors();
    return this;
};
Form.prototype.validate = function( /* Array */ checkFields)  {
    this.errorMessages = [];
    // console.log('validating');
    // checkFields is used to validate a single field, 
    // otherwise itereate through all compulsory fields

    // intersect used to clear the field we want to check 
    // from errorFields.  if still an error it will add again.

    function intersect(a, b) {
        var t;
        if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
        return a.filter(function (e) {
            return b.indexOf(e) > -1;
        });
    }


    var validated = true, fields = [];
    if (checkFields && this.validateFields) {
        var fields = intersect(this.validateFields, checkFields);

        for (var j=0; j<fields.length;j++) {
            var fieldName = fields[j].split('.').reverse()[0];
            var index = this.errorFields.indexOf(fieldName);
            if (index === -1) break;
            this.errorFields.splice(index, 1);
        }
    } else {

        var fields = this.validateFields || [];
        this.errorFields = []; // reset and re-calcuate all fields
    }
    for (var i=0;i<fields.length; i++) {
        var key = fields[i];
        var keySplit = key.split('.');
        var scope = this.data;
        for(var j=0; j<keySplit.length; j++) {

            if (!scope[keySplit[j]]) {
                scope = false;
                break;
            }
            if(j == keySplit.length -1 ) {
                scope = scope[keySplit[j]];
                break;
            }
            scope = scope[keySplit[j]];
        }
        // DO THE VALIDATE!!!
        var fieldValidators = this.validateRules[key];
        if (fieldValidators.length > 0) {

            var fieldname = fields[i].split('.').reverse()[0];
            for (var k=0; k<fieldValidators.length; k++) {
                let validator = fieldValidators[k];
                let arg = null;
                if (validator.indexOf('|')) {
                    let split = validator.split('|');
                    validator = split[0];
                    arg = split[1];
                };

                if (!this.validators[validator]) {
                    continue;
                }
                const result = this.validators[ validator ](scope, arg);
                if ( !result[0] ) {
                    this.errorFields.push(fieldname);
                    if (result[1]) {
                        let upperField = fieldname[0].toUpperCase() + fieldname.slice(1);
                        this.errorMessages.push( upperField + " " + result[1] );
                    }
                    validated = false;
                    break;
                }
            }
        }
    }
    
    return validated;
};
Form.prototype.events = function( /* Array */ checkFields)  {
    var self = this;

    const formInputsQuery = '#'+this.id +' input, #'+this.id +' textarea';
    const fields = document.querySelectorAll(formInputsQuery);
    for (let i = 0; i<fields.length; i++) {
        fields[i].addEventListener('change', (e) => {
            e.preventDefault();
            e.stopPropagation();
            var data = {};
            var elem = e.target;
            const elemid = elem.getAttribute('name');
            const inputType = elem.getAttribute('type');
            if (inputType == 'text' || inputType == 'email' || inputType == 'password' || inputType == 'hidden') {
                data[elemid] = elem.value;

            } else if (inputType =='checkbox') {
                var value = elem.checked;
                data[elemid] = value;
            }
            self.updateData(data);

            var validated = self.validate([elemid]);
            self.render();

        });
    
    }

    var form = document.getElementById(this.id);
    if (form != null) {
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            // console.log(self);
            self.submit(event);
        });
    }
}





export const Validators = {
    'notEmpty' : function(input) {
        return [
            !input ? false : true,
            ""  
        ];
    },
    'minLength' : function(input, length) {
        const len = parseInt(length);
        return [
            input.length >= len,
            "must be at least " + len + " characters long"
        ];
    },
    'maxLength' : function(input, length) {
        const len = parseInt(length);
        
        return [
            len >= input.length,
            "must be no more than " + len + " characters long, currently " + input.length
        ];
    },
    'isNumeric' : function(n) {
        // var ret = !isNaN(parseFloat(n)) && isFinite(n);
        return [
            !isNaN(parseFloat(n)) && isFinite(n),
            "must be a number"
        ];
    },
    'isTrue' : function(data) {
        return [
            (data === 'true' || data === true) ? true : false,
            "must be true"
        ];
    },
    'isSelected' : function(data) {
        return [
            (data === 'true' || data === true) ? true : false,
            "must be selected"
        ];
    }
};

