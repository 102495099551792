import { View } from '../view.js';



const HalfCut = function(data) {
    this.data = data;
    
    this.elements = {
        score: document.getElementById('env_halfcut_score'),
    }

    return this;
 }

HalfCut.prototype = new View();
HalfCut.constructor = HalfCut;

HalfCut.prototype.render = function() 
{
    this.elements.score.innerHTML = (this.data) ? this.data.value + this.data.unit_type : "";
}


export default HalfCut;